import { DataVariable, CodeAndEnglish, DataSource } from "./ModelClasses";

//Ookla data
//Data types
export const ooklaDownload = new DataVariable("ooklaDownload", "Ookla Average Download Speed", "Mbps");
export const ooklaUpload = new DataVariable("ooklaUpload", "Ookla Average Upload Speed", "Mbps");
export const ooklaTests = new DataVariable("ooklaTests", "Ookla Tests", "Count");
export const ooklaMobileDownload = new DataVariable("ooklaMobileDownload", "Ookla Average Mobile Download Speed", "Mbps");
export const ooklaMobileUpload = new DataVariable("ooklaMobileUpload", "Ookla Average Mobile Upload Speed", "Mbps");
export const ooklaMobileTests = new DataVariable("ooklaMobileTests", "Ookla Mobile Tests", "Count");
//Data times
export const q12019 = new CodeAndEnglish("2019q1", "Q1 2019");
export const q22019 = new CodeAndEnglish("2019q2", "Q2 2019");
export const q32019 = new CodeAndEnglish("2019q3", "Q3 2019");
export const q42019 = new CodeAndEnglish("2019q4", "Q4 2019");
export const q12020 = new CodeAndEnglish("2020q1", "Q1 2020");
export const q22020 = new CodeAndEnglish("2020q2", "Q2 2020");
export const q32020 = new CodeAndEnglish("2020q3", "Q3 2020");
export const q42020 = new CodeAndEnglish("2020q4", "Q4 2020");
export const q12021 = new CodeAndEnglish("2021q1", "Q1 2021");
export const q22021 = new CodeAndEnglish("2021q2", "Q2 2021");
export const q32021 = new CodeAndEnglish("2021q3", "Q3 2021");
export const q42021 = new CodeAndEnglish("2021q4", "Q4 2021");
export const q12022 = new CodeAndEnglish("2022q1", "Q1 2022");
export const q22022 = new CodeAndEnglish("2022q2", "Q2 2022");
export const q32022 = new CodeAndEnglish("2022q3", "Q3 2022");
export const q42022 = new CodeAndEnglish("2022q4", "Q4 2022");
export const q12023 = new CodeAndEnglish("2023q1", "Q1 2023");
export const q22023 = new CodeAndEnglish("2023q2", "Q2 2023");
export const q32023 = new CodeAndEnglish("2023q3", "Q3 2023");
export const q42023 = new CodeAndEnglish("2023q4", "Q4 2023");
export const q12024 = new CodeAndEnglish("2024q1", "Q1 2024");
export const q22024 = new CodeAndEnglish("2024q2", "Q2 2024");
export const OoklaData = new DataSource("Speed Test (Ookla)", [ooklaDownload, ooklaUpload, ooklaTests], [q12019, q22019, q32019, q42019, q12020, q22020, q32020, q42020, q12021, q22021, q32021, q42021,q12022, q22022, q32022, q42022, q12023, q22023, q32023, q42023, q12024, q22024], "%Yq%q");
export const OoklaMobileData = new DataSource("Mobile Speed Test (Ookla)", [ooklaMobileDownload, ooklaMobileUpload, ooklaMobileTests], [q12019, q22019, q32019, q42019, q12020, q22020, q32020, q42020, q12021, q22021, q32021, q42021,q12022, q22022, q32022, q42022, q12023, q22023, q32023, q42023, q12024, q22024], "%Yq%q");
