/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-key */
import React from "react";

//React Select option
function SelectOption(props) {
	return <option value={props.value}>{props.value}</option>;
}
//React radio button
function RadioButton(props) {
	return <label htmlFor={props.code} id={props.code + props.form + "Label"}>
		<input type="radio" id={props.code + props.form} name={props.form} value={props.code} defaultChecked={props.checked} />
		{props.english}</label>;
}
//React dropdown form
export function DropdownForm(props) {
	return <form id={props.id} style={{display: "inline-block"}} className={props.className}>
		<select value={props.value} onChange={props.onChangeFunction} style={props.style} className={props.className}>
			{props.data.map((dataElement) => dataElement.name).map((name) => <SelectOption key={name} value={name} />)}
		</select>
	</form>;
}
//React radio button form
export function RadioButtonForm(props) {
	return <form id={props.id}>
		<div onChange={props.onChangeFunction}>
			{props.data.map((dataElement) => <div>
				<RadioButton key={dataElement.code + props.keyCode} english={dataElement.name} code={dataElement.code}
					form={props.id} checked={dataElement === props.comparison ? true : false} />
			</div>)}
		</div>
	</form>;
}
